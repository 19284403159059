import { isValidFirebaseUrl } from "../utils/validation_firebase";
import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function iperc({ year }) {
    const url = 'iperc/get?year=' + year;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function createIperc({ name, code, area, timestamp_approval, timestamp_revision, document_url }) {
    try {
        if (document_url == null && document_url === undefined) {
            return
        }
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/iperc/');
        if (document_url?.message || typeof document_url !== 'string') {
            console.info('No es un string');
            console.info(document_url?.message)
            return false;
        }
        if (!isValidFirebaseUrl(document_url)) {
            console.info('No es una url de firebase');
            return false;
        }
        const url = 'iperc/store';
        const data = {
            'name': name,
            'code': code,
            'area': area,
            'timestamp_approval': timestamp_approval,
            'timestamp_revision': timestamp_revision,
            'document_url': document_url
        };
        return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
    }
    catch (err) {
        console.err(err);
    }
}

function editIperc({ id_iperc }) {
    const url = 'iperc/edit/' + id_iperc;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function updateIperc({ id_iperc, name, code, area, timestamp_approval, document_url, timestamp_revision }) {
    if (document_url !== null && document_url !== undefined) {
        document_url = await uploadFileCompanies(document_url);
    }
    const url = 'iperc/update/' + id_iperc;
    const data = {
        'name': name,
        'code': code,
        'area': area,
        'timestamp_approval': timestamp_approval,
        'timestamp_revision': timestamp_revision,
        'document_url': document_url
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

async function updateIpercStatuses({ id_iperc, id_iperc_statuses }) {
    const url = 'iperc/updatestatuses/' + id_iperc;
    const data = {
        'id_iperc_statuses': id_iperc_statuses,
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}
async function updateIpercArchived({ id_iperc, archived }) {
    const url = 'iperc/updatearchived/' + id_iperc;
    const data = {
        'archived': archived,
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

async function deleteIperc({ id_iperc }) {
    const url = 'iperc/delete/' + id_iperc;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

const MyIperc = {
    iperc, createIperc, editIperc, updateIperc, updateIpercStatuses, updateIpercArchived, deleteIperc
}

export default MyIperc;
