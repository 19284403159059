import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  MenuList,
} from "@mui/material";
import {
  AccountCircle,
  AttachFile,
  Settings,
  Home,
  Description,
  Group,
  ArrowBackIosNew,
  ArrowForwardIos,
  Logout,
  Error,
  ForumOutlined,
  AdminPanelSettings,
  VisibilityOutlined,
  FitnessCenter,
} from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAutocomplete from "@mui/material/useAutocomplete";
import { Link } from 'react-router-dom';

import navLinkData from "./menu.json";
import { Link as LinkRouter } from "react-router-dom";

import MyLogin from "../../api/MyLogin";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import MyLocalStorage from "../../api/MyLocalStorage";
import UsersPicturesControla from "../images/usersPicturesControla";
import MyNotifications from "../../api/MyNotifications";
import MyCrypto from "../../api/MyCrypto";
import MySessionStorage from "../../api/MySessionStorage";
import LoadingControla from "../load/loadingControla";
import ButtonIconControla from "../buttons/buttonIconControla";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssignmentIcon from '@mui/icons-material/Assignment';

// import { Paper } from '@mui/material';

const drawerBlue = "#0F1255";
const uDrawerText = "#65A3FF";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

function JsonMenuIcon(JsonIcon) {
  switch (JsonIcon.JsonIcon) {
    case "Home":
      return <Home />;
    case "AttachFile":
      return <AttachFile />;
    case "Group":
      return <Group />;
    case "Description":
      return <Description />;
    case "Settings":
      return <Settings />;
    case "ForumOutlined":
      return <ForumOutlined />;
    case "AdminPanelSettings":
      return <AdminPanelSettings />;
    case "Fitness":
      return <FitnessCenter />;
    //libro de acta pequeñas empr
    case "Person":
      return <MenuBookIcon />;
    case "Activities":
      return <AssignmentIcon />;

    default:
      return <Error />;
  }
}

// const Label = styled('label')({
//   display: 'block',
// });

const Input = styled("input")(({ theme }) => ({
  // width: 200,
  width: "20ch",
  backgroundColor: "#f5f4f6",
  color: "#9191b0",
  border: "none",
  borderRadius: "16px",
  padding: theme.spacing(1.7, 1.7, 1.7, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  transition: theme.transitions.create("width"),
  [theme.breakpoints.up("md")]: {
    width: "50ch",
  },
  "&:focus": {
    border: "2px solid blue",
    // backgroundColor: 'red',
    outlineColor: "transparent",
  },
}));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));

const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 300,
  border: "1px solid rgba(0,0,0,.25)",
  "& li.Mui-focused": {
    backgroundColor: "#4a8df6",
    color: "white",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "#2977f5",
    color: "white",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const messageAlert = MySessionStorage.getSession("messageAlert");
const typeAlert = MySessionStorage.getSession("typeAlert");

export default function SideNav({ returnBack }) {
  const [openAlert, setOpenAlert] = React.useState(
    messageAlert !== null &&
      messageAlert !== undefined &&
      messageAlert !== "" &&
      typeAlert !== null &&
      typeAlert !== undefined &&
      typeAlert !== ""
      ? true
      : false
  );

  const onBack = () => {
    if (returnBack) {
      navigate(returnBack)
    } else {
      navigate(-1)
    }
  }

  const navigate = useNavigate();

  const handleCloseAlert = () => {
    setOpenAlert(false);
    MySessionStorage.clearSession();
  };

  const [openLoadView, setOpenLoadView] = React.useState(false);
  const userData = MyConfigurationApi.userData();
  let usersRoles = {};

  if (Object.entries(userData).length === 0) {
    console.log("Se cerró sesión (token expirado)");
  } else {
    usersRoles = userData.roles;
  }

  const [rowsNotifications, setRowsNotifications] = React.useState([]);
  const [notificationsCount, setNotificationsCount] = React.useState(0);

  const handleGetNotifications = async () => {
    try {
      const response = MyConfigurationApi.userData();

      const responseNotification = await MyNotifications.notificationsPersonal({
        idUser: response.id_users,
      }) ?? null;
      
      // This control the no notifications
      if (!responseNotification || responseNotification.success === false) {
        navigate('/login');
        return window.location.reload();
      }
      setRowsNotifications(responseNotification ?? null);
      setNotificationsCount(responseNotification.length === undefined ? 0 : responseNotification.length);
    }
    catch (error) {
      console.error("No se pudo cargar las notificaciones. " + error)
    }
  };

  const handleGetNotificationsStatus = async (notification) => {
    const response = await MyNotifications.notificationsStatus({
      idNotification: notification.id_notifications
    });

    if (response.document_url.startsWith('https://firebasestorage.googleapis.com')) {
      // Abrir en una nueva ventana o pestaña
      window.open(response.document_url, '_blank');
      navigate('/')
    } else {
      // Utilizar navigate
      navigate(response.document_url + '?idTabs=1');
    }
  };

  useEffect(() => {
    handleGetNotifications()
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [anchorNotificationsEl, setAnchorNotificationsEl] =
    React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isNotificationsOpen = Boolean(anchorNotificationsEl);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleNotificationsClose();
  };

  const handleNotificationsOpen = (event) => {
    setAnchorNotificationsEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorNotificationsEl(null);
    handleMobileNotificationsClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileNotificationsOpen = (event) => {
    setAnchorNotificationsEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileNotificationsClose = () => {
    setAnchorNotificationsEl(null);
  };

  const handleLogout = async () => {
    try {
      setOpenLoadView(true);
      await MyLogin.sessionLogout();
    }
    catch (err) {
      console.log(err)
    }
    if (!navigate('/login', { replace: true })) {
      window.location.reload();
    }
  };

  // const [openSearchMenu, setOpenSearchMenu] = React.useState(false);
  const [anchorSearchMenu, setAnchorSearchMenu] = React.useState(null);
  const openSearchMenu = Boolean(anchorSearchMenu);

  const handleOpenSearchMenu = async (event) => {
    setAnchorSearchMenu(event.currentTarget);
    // setOpenSearchMenu(true);
  };

  const handleCloseSearchMenu = async () => {
    setAnchorSearchMenu(null);
    // setOpenSearchMenu(false);
  };

  const redirectToNotifications = () => {
    navigate("/mailbox/notifications");
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Mi Perfil</MenuItem>
      <MenuItem
        onClick={() => {
          handleLogout();
        }}
      >
        Cerrar Sesion
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 4 new mails"
          color="inherit"
          onClick={() => {
            handleLogout();
          }}
        >
          <Logout />
        </IconButton>
        <p>Desloguearse</p>
      </MenuItem>
      <MenuItem onClick={handleNotificationsOpen}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationsCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaciones</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <UsersPicturesControla textDescription={"Perfil"} />
        {/* <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> 
        <p>Perfil</p>*/}
      </MenuItem>
    </Menu>
  );

  const notificationsId = "primary-search-notifications";
  const renderNotifications = (
    <Menu
      anchorEl={anchorNotificationsEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={notificationsId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNotificationsOpen}
      onClose={handleNotificationsClose}
    >
      <MenuItem
        sx={{
          height: "50px",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {" "}
        Tienes {notificationsCount} notification(es){" "}
      </MenuItem>
      {rowsNotifications?.map((row) => (
        <MenuItem
          key={row.id_notifications}
          onClick={() => handleGetNotificationsStatus(row)}
        >
          {row.message}
        </MenuItem>
      ))}
      <MenuItem
        onClick={() => redirectToNotifications()}
      >
        Ver todas la notificaciones
      </MenuItem>
    </Menu>
  );

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    options: navLinkData,
    getOptionLabel: (option) => option.label,
  });

  const { sectionsModules } = useAppContext();

  return (
    <Box sx={{ display: "flex" }}>
      <LoadingControla
        openLoading={false}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{ backgroundColor: "white", color: "#9191B0" }}
      >
        <Toolbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoadView}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <img
              src="/assets/icons/smallcontrolaicon.png"
              alt="smallcontrolalicon"
              style={{
                maxWidth: "25px",
              }}
            ></img>
          </IconButton>

          {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={onBack}
            >
              <ArrowBackIosNew />
            </IconButton> */}

          <Search
            sx={{
              backgroundColor: "#F5F4F6",
              borderRadius: "16px",
            }}
          >
            <div>
              {/* <div {...getRootProps()}> */}
              {/* <Label {...getInputLabelProps()}>useAutocomplete</Label> */}
              {/* <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper> */}
              {/* <StyledInputBase
                  placeholder="Buscar"
                  inputProps={{ 'aria-label': 'search' }}
                  sx={{ width: '100%!important' }}
                  onChange={handleOpenSearchMenu}
                  {...getInputProps()}
                /> */}
              {/* <Input {...getInputProps()} placeholder="Buscar" /> */}
              {/* </div> */}
              {/* {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                  {groupedOptions.map((option, index) => (
                    <Link
                      key={option.id}
                      sx={{ textDecoration: "none" }}
                      href={option.url}
                    >
                      <MenuItem {...getOptionProps({ option, index })}>
                        {option.label}
                      </MenuItem>
                    </Link>
                  ))}
                </Listbox>
              ) : null} */}
            </div>
            {/* <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar"
              inputProps={{ 'aria-label': 'search' }}
              sx={{ width: '100%!important' }}
              onChange={handleOpenSearchMenu}
            /> */}
            {/* <Menu
              anchorEl={anchorSearchMenu}
              open={openSearchMenu}
            // onClose={handleCloseSearchMenu}
            >
              <MenuItem>Prueba</MenuItem>
              <MenuItem>Prueba</MenuItem>
              <MenuItem>Prueba</MenuItem>
            </Menu  > */}
          </Search>
          {/* <Typography variant="h6" noWrap component="div">
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => {
                handleLogout();
              }}
            >
              <Logout />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              aria-label="Notificaciones del usuario"
              aria-controls={notificationsId}
              aria-haspopup="true"
              onClick={handleMobileNotificationsOpen}
            >
              <Badge badgeContent={notificationsCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="Perfil del usuario"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <UsersPicturesControla />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <IconButton>
              <AccountCircle />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderNotifications}
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: drawerBlue,
            color: uDrawerText,
          },
        }}
      >
        <DrawerHeader>
          <img
            className="logo"
            src="/assets/icons/controlaLogo.png"
            alt="LogoControla"
            style={{
              width: "100%",
              maxWidth: "150px",
            }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon
                sx={{
                  color: "white",
                }}
              />
            ) : (
              <ChevronLeftIcon
                sx={{
                  color: "white",
                }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "100px",
          }}
        >

          {Array.isArray(sectionsModules) && sectionsModules.length > 0 ? (
            <>
              {sectionsModules
                .filter(post =>
                  Object.entries(usersRoles).length >= 1 &&
                  post.roles.some(role => usersRoles.includes(role))
                )
                .map(post => {
                  let showSection = true

                  if (post.committeeMembers) {
                    showSection = false

                    if (post.committeeMembers.includes(userData.id_users) || [1, 7, 8].some(role => usersRoles.includes(role))) {
                      showSection = true
                    }
                  }

                  return showSection && (
                    <ListItem
                      visible={showSection ? "show visible" : "no soy visible"}
                      disablePadding
                      sx={{ display: "block" }}
                      key={post.id}
                    >
                      <ListItemButton
                        component={Link}
                        to={{ pathname: post.url }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: uDrawerText,
                          }}
                        >
                          <JsonMenuIcon JsonIcon={post.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={post.label}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
            </>
          ) : (
            <CircularProgress />
          )}
        </List>
      </Drawer>
    </Box>
  );
}
