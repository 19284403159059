import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Grid, Typography, InputBase } from "@mui/material";
import TitleText from "../../components/text/titleText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import { FileOpenOutlined, Search } from "@mui/icons-material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import MyNotifications from "../../api/MyNotifications";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import ButtonControla from "../../components/buttons/buttonController";
import { useNavigate } from "react-router-dom";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

// input buscar
const SearchTextField = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  border: "2px solid #C6CFFA",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

function Index() {
  // mostrar mensajes
  const [notificationsGeneral, setNotificationsGeneral] = useState([]);
  const [notificationId, setNotificationId] = useState([]);
  const userData = MyConfigurationApi.userData()
  const idUser = userData.id_users;

  const navigate = useNavigate()

  const compararFechasDescendente = (a, b) => {
   const fechaA = new Date(a.timestamp_created);
    const fechaB = new Date(b.timestamp_created);

    // Primero comparar las fechas
    if (fechaA.getTime() !== fechaB.getTime()) {
      return fechaB - fechaA; // Si las fechas son diferentes, ordenar por fecha descendente
    } else {
      // Si las fechas son iguales, comparar las horas y los minutos
      const horaA = fechaA.getHours();
      const minutoA = fechaA.getMinutes();
      const horaB = fechaB.getHours();
      const minutoB = fechaB.getMinutes();

      // Si las horas son diferentes, ordenar por hora descendente
      if (horaA !== horaB) {
        return horaB - horaA;
      } else {
        // Si las horas son iguales, ordenar por minuto descendente
        return minutoB - minutoA;
      }
    }
  };

  const getNotifications = async () => {
    const response = await MyNotifications.notifications();
    
    setNotificationsGeneral(response);
  };

  // detaller derecha del sms
  const handleShowSent = (notification) => {
    setNotificationId(notification.id_notifications);
  };

  const [nameUserLogged, setNameUserLogged] = useState();
  const [notificationsPersonal, setNotificationsPersonal] = useState([]);

  const handleUserLogged = async () => {
    const response = MyConfigurationApi.userData();
    setNameUserLogged(response.first_name + " " + response.last_name);
    const responseNotification = await MyNotifications.notificationsPersonal({
      idUser: response.id_users,
    });
    setNotificationsPersonal(responseNotification);
  };

  const handleGoTo = (documentUrl) => {
    const isExternalUrl = documentUrl.startsWith('http');

    if (isExternalUrl) {
      window.open(documentUrl, '_blank');
    } else {
      navigate(documentUrl);
    }
  };

  // notificaciones personales

  const [notificationsTotal, setNotificationsTotal] = useState([]);
  useEffect(() => {
    const listNotificationTotal = [...notificationsPersonal, ...notificationsGeneral]
    const listNotificationTotalOrdenado = listNotificationTotal.sort(compararFechasDescendente);
    setNotificationsTotal(listNotificationTotalOrdenado);
    setRowsMailSendSearch([...notificationsPersonal, ...notificationsGeneral]);

  }, [notificationsPersonal, notificationsGeneral]);

  // buscar mensaje
  const [rowsMailSendSearch, setRowsMailSendSearch] = useState([]);

  useEffect(() => {
    setRowsMailSendSearch(notificationsTotal)
  }, [notificationsTotal])

  const handleSearchMessage = (searchMessage) => {
    if (searchMessage !== "") {
      let expresion = new RegExp(`${searchMessage}.*`, "i");
      let sendMailSearch = notificationsTotal.filter(
        (x) =>
          expresion.test(x.message) ||
          expresion.test(x.first_name_sender) ||
          expresion.test(x.last_name_sender)
      );

      setRowsMailSendSearch(sendMailSearch);
    } else {
      setRowsMailSendSearch(notificationsTotal);
    }
  };

  const handleGetNotificationsStatus = async (idNotification) => {
    const response = await MyNotifications.notificationsStatus({
      idNotification: idNotification
    });
    getNotifications();
    return response;
   };

  useEffect(() => {
    getNotifications();
    handleUserLogged();
  }, []);

  return (
    <Box sx={{  height: "100%" }}>
      <ReturnBack returnBack="/mailbox"/>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
                originUrl={"/mailbox/notifications"}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <SubtitleText
                text={"Buzón de participación"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Notificaciones"}></TitleText>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              justifyContent="flex-end"
              style={{ textAlign: "center" }}
            >
              <CompanyLogoControla />
            </Grid>

          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <SearchTextField
                onChange={({ target }) => handleSearchMessage(target.value)}
              >
                <SearchIconWrapper>
                  <Search />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Buscar ..."
                  inputProps={{ "aria-label": "search" }}
                />
              </SearchTextField>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, mb: 4 }}>
          <Grid container spacing={2}>
            {rowsMailSendSearch !== null ? (
              <Grid
                item
                md={8}
                style={{ textAlign: "center" }}
                sx={{
                  textAlign: "left",
                  overflowY: "scroll",
                  maxHeight: "400px",
                  paddingTop: "0 !important",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "80px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#222ADA",
                  },
                }}
              >
                {rowsMailSendSearch.map((notification, key) => (
                  <Card
                    onClick={() => {
                      handleShowSent({
                        notification,
                      });
                    }}
                    sx={{ mt: 2 }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      padding: "17px",
                      border:
                        setNotificationId === notification.id_notifications
                          ? "2px solid #1538f5"
                          : "",
                    }}
                    key={key}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={8} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            De:
                            {" " +
                              notification.first_name_sender +
                              " " +
                              notification.last_name_sender}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            Para:
                            {" " + nameUserLogged}
                          </Typography>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#9191B0" }}
                          >
                            {notification.timestamp_created.substring(0, 16) +
                              " hrs"}
                          </Typography>
                        </Grid>
                        <Grid item md={11} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              color: "#091641",
                              fontWeight: "400",
                            }}
                          >
                            {notification.message}
                          </Typography>
                        </Grid>

                        <Grid item md={11} style={{ textAlign: "left" }}>
                          {
                            !(notification.views.some(item => item.id_users_view == idUser)) ?
                              <ButtonControla
                                textButton={"Leído"}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#1976d2"}
                                tooltipText={'Si el mensaje es marcado como leído se eliminará dentro de 3 días'}
                                functionOnClick={() => handleGetNotificationsStatus(notification.id_notifications)}
                              />
                              : <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#D2355B",
                                  fontWeight: "400",
                                }}
                              >
                                Mensaje leído
                              </Typography>
                          }
                        </Grid>

                        {notification.document_url !== null ? (
                          <Grid item md={1} style={{ textAlign: "right" }}>
                            <ButtonIconControla
                              icon={
                                <FileOpenOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver archivo"}
                              functionOnClick={() => handleGoTo(notification.document_url + '?idTabs=1')}
                              directionTarget={"_blank"}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#2F80ED",
                    fontWeight: "600",
                  }}
                >
                  Bandeja vacía
                </Typography>
                <img
                  src="/assets/images/banners/mailbox/bannerRecibidos.png"
                  alt="gif"
                />
              </Grid>
            )}
            <Grid
              item
              md={4}
              sx={{
                textAlign: "center",
                overflowY: "scroll",
                maxHeight: "523px",
                paddingTop: "0 !important",
              }}
            >
              <img
                src="/assets/images/banners/mailbox/felicitaciones.png"
                alt="gif"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
