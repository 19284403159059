import { React, useState, useEffect } from "react";

import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Divider,
  InputLabel,
  MenuItem,
  DialogActions,
  InputBase,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { Add, ArrowBack, Delete, Save, Search } from "@mui/icons-material";
import TitleText from "../../components/text/titleText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import ButtonControla from "../../components/buttons/buttonController";
import SelectYesNotControla from "../../components/selects/selectYesNotControla";
import MyCompanies from "../../api/MyCompanies";
import SelectControla from "../../components/selects/selectControla";
import SearchUsersControla from "../../components/search/searchUsersControla";
import MyUsers from "../../api/MyUsers";
import { useAppContext } from "../../context/AppContext";
import SearchButtonControla from "../../components/search/searchButtonControla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function GeneralInformation() {
  const [openModalCreateSector, setOpenModalCreateSector] = useState(false);
  const [sectors, setSectors] = useState(0);
  const { setLogoCompany, logoCompany, permissions } = useAppContext();

  const handleOpenModalCreateSector = () => {
    handleGetCompaniesNotSectors();
    setSectors("0");
    setOpenModalCreateSector(true);
  };

  const [openModalSelectRepresentation, setOpenModalSelectRepresentation] =
    useState(false);

  const [openModalSelectSyndicate, setOpenModalSelectSyndicate] =
    useState(false);

  const [name, setName] = useState("");
  const [ruc, setRuc] = useState("");
  const [logo, setLogo] = useState(null);
  const [regimes, setRegimes] = useState("");
  const [types, setTypes] = useState("");
  const [department, setDepartamet] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [urbanization, setUrbanization] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [syndicate, setSyndicate] = useState("");
  const [activitiesPrimary, setActivitiesPrimary] = useState("");
  const [activitiesSecondary, setActivitiesSecondary] = useState("");

  const [legallyRepresentative, setLegallyRepresentative] = useState(" ");
  const [idLegallyRepresentative, setIdLegallyRepresentative] = useState("");

  const [syndicateRepresentative, setSyndicateRepresentative] = useState(" ");
  const [idSyndicateRepresentative, setIdSyndicateRepresentative] =
    useState("");

  const [rowsActivities, setRowsActivities] = useState([]);

  const [rowsTypes, setRowsTypes] = useState([]);
  const [rowsRegimes, setRowsRegimes] = useState([]);

  const [rowsSectors, setRowsSectors] = useState([]);
  const [rowsNotSectors, setRowsNotSectors] = useState([]);

  const [rowsUsers, setRowsUsers] = useState([]);

  const handleGetCompaniesTypes = async () => {
    const response = await MyCompanies.types();
    if (response.success !== false) {
      setRowsTypes(response);
    } else {
      alert("Error al listar la organizacion juridica!");
    }
  };

  const handleGetCompaniesRegimes = async () => {
    const response = await MyCompanies.regimes();
    if (response.success !== false) {
      setRowsRegimes(response);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleGetCompaniesSectors = async () => {
    const response = await MyCompanies.sectorsCompanies();
    if (response.success !== false) {
      setRowsSectors(response);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleGetCompaniesNotSectors = async () => {
    const response = await MyCompanies.sectorsNotCompanies();
    if (response.success !== false) {
      setRowsNotSectors(response);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleGetCompany = async () => {
    const response = await MyCompanies.company();
    if (response.success !== false) {
      setName(response.name);
      setRuc(response.ruc);
      setRegimes(response.id_companies_regimes);
      setTypes(response.id_companies_types);
      setDepartamet(response.department);
      setProvince(response.province);
      setDistrict(response.district);
      setUrbanization(response.urbanization);
      setAddress(response.address);
      setPhone(response.phone);
      setEmail(response.email);
      setWeb(response.web);
      setSyndicate(response.syndicate);
      setActivitiesPrimary(response.id_companies_activities_primaries);
      setActivitiesSecondary(response.id_companies_activities_secondaries);
      if (response.id_users_legally_representative != null) {
        handleSelectUsersRepresentation(
          response.id_users_legally_representative
        );
      }
      if (
        response.syndicate == 1 &&
        response.id_users_syndicate_representation != null
      ) {
        handleSelectUsersSyndicate(response.id_users_syndicate_representation);
      }
    } else {
      alert("Error al listar la organizacion juridica!");
    }
  };

  const handleGetActivities = async () => {
    const response = await MyCompanies.activities();
    if (response.success !== false) {
      setRowsActivities(response);
    } else {
      alert("Error al listar las actividades!");
    }
  };

  useEffect(() => {
    handleGetCompany();
    handleGetCompaniesTypes();
    handleGetCompaniesRegimes();
    handleGetCompaniesSectors();
    handleGetUsers();
    handleGetActivities();
  }, []);

  const handleStoreCompaniesSectors = async (event) => {
    event.preventDefault();
    const response = await MyCompanies.storeSectorsCompanies({
      id_companies_sectors: sectors,
    });
    if (response.success !== false) {
      handleGetCompaniesSectors();
      setOpenModalCreateSector(false);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleDeleteCompaniesSectors = async (
    id_companies_sectors_rels_companies
  ) => {
    const response = await MyCompanies.deleteSectorsCompanies({
      id_companies_sectors_rels_companies: id_companies_sectors_rels_companies,
    });
    if (response.success !== false) {
      handleGetCompaniesSectors();
      alert(response.msg);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleGetUsers = async (event) => {
    const response = await MyUsers.users();
    if (response.success !== false) {
      setRowsUsers(response.users_actives);
    } else {
      alert("Error al listar la Los regimen!");
    }
  };

  const handleSelectUsersRepresentation = async (idUsers) => {
    setIdLegallyRepresentative(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setLegallyRepresentative(
        response.document +
        " - " +
        response.first_name +
        " " +
        response.last_name
      );
      setOpenModalSelectRepresentation(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const handleSelectUsersSyndicate = async (idUsers) => {
    setIdSyndicateRepresentative(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setSyndicateRepresentative(
        response.document +
        " - " +
        response.first_name +
        " " +
        response.last_name
      );
      setOpenModalSelectSyndicate(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });

  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const handleUpdateCompany = async (event) => {
    event.preventDefault();
    const requestData = {
      name: name,
      ruc: ruc,
      address: address,
      department: department,
      province: province,
      district: district,
      urbanization: urbanization,
      phone: phone,
      web: web,
      email: email,
      id_companies_regimes: regimes,
      id_companies_types: types,
      id_activities_primaries: activitiesPrimary,
      id_activities_secondaries: activitiesSecondary,
      syndicate: syndicate,
      logo_url: logo,
      id_users_syndicate_representation: idSyndicateRepresentative,
      id_users_legally_representative: idLegallyRepresentative,
      logo_isSaved: false
    };

    // Agregar el campo logo_url solo si el valor de logo no es null
    if (logo == null) {
      requestData.logo_url = logoCompany;
      requestData.logo_isSaved = true;
    }

    const response = await MyCompanies.updateGeneralInformation(requestData);
    if (!response || response.success === false) {
      handleClickAlert('error', `No se pudo actualizar la empresa. ${response?.message}`.trimEnd());
    }
    if (response.success !== false) {
      handleClickAlert('success','Datos actualizados correctamente!');
      if(response?.logo_url) {
        setLogoCompany(response.logo_url)
      }
    }
  };

  return (
    <Box>
      <ReturnBack />
      {/* componente Alert */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Container>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ButtonMailboxControla
                originUrl={"/settings/company/generalinformation"}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <SubtitleText text={"Configuración"} color={colorTitle} />
              <TitleText text={"Perfil de mi empresa"} />
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            </Grid>

            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>

          <form onSubmit={handleUpdateCompany}>
            <Box sx={{ marginTop: 3 }}>
              <SubtitleText text={"Datos Generales"} color={blue} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                  <InputControlaVertical
                    text={"Razon social:"}
                    inputType={"text"}
                    value={name}
                    inputValue={setName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"RUC:"}
                    inputType={"text"}
                    value={ruc}
                    inputValue={setRuc}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {/* <InputControlaVertical text={"Organización jurídica:"} inputType={"text"} /> */}
                  <SelectControla
                    text={"Organización jurídica:"}
                    inputValue={setTypes}
                    modalType={false}
                    childrenRows={rowsTypes.map((row) => (
                      <MenuItem
                        key={row.id_companies_types}
                        value={row.id_companies_types}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    value={types}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {/* <InputControlaVertical text={"Régimen:"} inputType={"text"} /> */}
                  <SelectControla
                    text={"Régimen:"}
                    inputValue={setRegimes}
                    modalType={false}
                    childrenRows={rowsRegimes.map((row) => (
                      <MenuItem
                        key={row.id_companies_regimes}
                        value={row.id_companies_regimes}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    value={regimes}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    roles={permissions.CONF_EMP_INFO_VER}
                    titleLabel="Representante Legal:"
                    inputValue={legallyRepresentative}
                    functionClick={() => setOpenModalSelectRepresentation(true)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <SubtitleText
                text={"Dirección y Contacto"}
                color={blue}
              ></SubtitleText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Departamento:"}
                    inputType={"text"}
                    inputValue={setDepartamet}
                    value={department}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Provincia:"}
                    inputType={"text"}
                    inputValue={setProvince}
                    value={province}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Distrito:"}
                    inputType={"text"}
                    inputValue={setDistrict}
                    value={district}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Urbanización:"}
                    inputType={"text"}
                    inputValue={setUrbanization}
                    value={urbanization}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Direccion:"}
                    inputType={"text"}
                    inputValue={setAddress}
                    value={address}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Teléfono:"}
                    inputType={"text"}
                    inputValue={setPhone}
                    value={phone}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Correo:"}
                    inputType={"text"}
                    inputValue={setEmail}
                    value={email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <InputControlaVertical
                    text={"Web:"}
                    inputType={"text"}
                    inputValue={setWeb}
                    value={web}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />
            <Box sx={{ marginTop: 3 }}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ marginTop: 3, marginBottom: 1 }}
              >
                <Box>
                  {/* <SubtitleText text={"Datos del trabajador"} color={blue} /> */}
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    Sector
                  </InputLabel>
                </Box>
                <Box>
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar actividad"}
                    functionOnClick={handleOpenModalCreateSector}
                  />
                </Box>
              </Stack>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Sector
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsSectors.map((row) => (
                      <TableRow
                        hover
                        key={row.id_companies_sectors_rels_companies}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              roles={permissions.CONF_EMP_INFO_ELIMINAR}
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                              functionOnClick={() =>
                                handleDeleteCompaniesSectors(
                                  row.id_companies_sectors_rels_companies
                                )
                              }
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <SelectControla
                    text={"Actividad principal:"}
                    inputValue={setActivitiesPrimary}
                    modalType={false}
                    childrenRows={rowsActivities.map((row) => (
                      <MenuItem
                        key={row.id_companies_activities}
                        value={row.id_companies_activities}
                      >
                        {row.code + " - " + row.name}
                      </MenuItem>
                    ))}
                    value={activitiesPrimary}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <SelectControla
                    text={"Actividad Secundaria:"}
                    inputValue={setActivitiesSecondary}
                    modalType={false}
                    childrenRows={rowsActivities.map((row) => (
                      <MenuItem
                        key={row.id_companies_activities}
                        value={row.id_companies_activities}
                      >
                        {row.code + " - " + row.name}
                      </MenuItem>
                    ))}
                    value={activitiesSecondary}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectYesNotControla
                    text={"Tiene sindicato:"}
                    inputValue={setSyndicate}
                    value={syndicate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {syndicate == 1 ? (
                    <>
                      <InputLabel
                        sx={{
                          padding: "8px",
                          fontSize: "18px",
                          color: "#305AD9",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Representante del sindicato:{" "}
                      </InputLabel>
                      <Stack direction="row">
                        <InputBase
                          type="text"
                          value={syndicateRepresentative}
                          readOnly
                          sx={{
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            fontFamily: "Roboto",
                            width: "100%",
                            padding: "4px 10px",
                            border: "1px solid transparent",
                            backgroundColor: "#FFFFFF",
                            color: "#305AD9",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            "&.Mui-focused": {
                              border: "1px solid blue",
                            },
                          }}
                        />
                        <Button
                          variant="contained"
                          type="button"
                          sx={{
                            borderRadius: "0",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            height: "42px",
                            backgroundColor: "#2D9CDB",
                            textTransform: "none",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "#33AEF4",
                            },
                          }}
                          onClick={() => {
                            setOpenModalSelectSyndicate(true);
                          }}
                          startIcon={<Search />}
                        >
                          Buscar
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputControlaVertical
                    text={"Logo:"}
                    inputType={"file"}
                    inputValue={setLogo}
                  />
                </Grid>
              </Grid>
            </Box>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="start"
              sx={{ marginTop: 3 }}
            >
              <Box>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  textButton={"Regresar"}
                  backgroundColor={"#CBCBFF"}
                  backgroundColorHover={"#d6d6ff"}
                  isNavegationOrigin={true}
                  url={-1}
                />
              </Box>
              <Box>
                <ButtonControla
                  roles={permissions.CONF_EMP_INFO_EDITAR}
                  iconButton={<Save />}
                  textButton={"Actualizar"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </form>
        </Box>

        <Dialog
          open={openModalCreateSector}
          onClose={() => {
            setOpenModalCreateSector(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Agregar sector
            </Typography>
          </DialogTitle>
          <form onSubmit={handleStoreCompaniesSectors}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <SelectControla
                text={"Sector:"}
                inputValue={setSectors}
                modalType={true}
                required
                value={sectors}
                childrenRows={rowsNotSectors.map((row) => (
                  <MenuItem
                    key={row.id_companies_sectors}
                    value={row.id_companies_sectors}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenModalCreateSector(false);
                }}
              >
                Cancelar
              </Button>

              <Button type="submit">Agregar</Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={openModalSelectRepresentation}
          onClose={() => {
            setOpenModalSelectRepresentation(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Seleccionar Representante Legal
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "0" }}>
            <SearchUsersControla
              handleUsersActionButton={handleSelectUsersRepresentation}
              rowsUser={rowsUsers}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalSelectRepresentation(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModalSelectSyndicate}
          onClose={() => {
            setOpenModalSelectSyndicate(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Seleccionar representante del sindicato
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "0" }}>
            <SearchUsersControla
              handleUsersActionButton={handleSelectUsersSyndicate}
              rowsUser={rowsUsers}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalSelectSyndicate(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default GeneralInformation;
